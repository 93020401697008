<template>
  <moe-page title="短信详情">
    <moe-form v-if="messageData.id" :showBack="false" :showClose="true">
      <moe-describe-list title="" :col="1" v-if="messageData.id">
        <moe-describe-item label="短信模版"> {{ messageData.title }} </moe-describe-item>
        <moe-describe-item label="模版内容"> {{ messageData.content }} </moe-describe-item>
        <moe-describe-item label="发送对象"> {{ $moe_format.getSendTargetList(messageData.sendTarget) }} </moe-describe-item>
        <moe-describe-item label="指定用户" v-if="messageData.sendTarget === 'SPECIFY_USER'">
          <div class="df aic fww">
            <moe-tag class="mr-10 mb-10" v-for="(item, index) in messageData.sendTargetData" :key="index">{{ `用户编号: ${item.id}` }}</moe-tag>
          </div>
        </moe-describe-item>
        <moe-describe-item label="指定标签用户" v-if="pushData.sendTarget === 'SPECIFY_TAG'">
          <div class="df aic fww">
            <moe-tag class="mr-10 mb-10" v-for="(item, index) in SPECIFY_USER_LIST" :key="index">{{ item }}</moe-tag>
          </div>
        </moe-describe-item>
        <moe-describe-item label="黑名单用户">
          <div class="df aic fww">
            <moe-tag class="mr-10 mb-10" v-for="(item, index) in messageData.sendExcludeData" :key="index">{{ `用户编号: ${item.id}` }}</moe-tag>
          </div>
        </moe-describe-item>

        <moe-describe-item label="发送类型"> {{ $moe_format.getPushOpportunityList(messageData.pushOpportunity) }} </moe-describe-item>

        <moe-describe-item label="发送时间"> {{ messageData.pushTime }} </moe-describe-item>
      </moe-describe-list>
    </moe-form>
  </moe-page>
</template>

<script>
export default {
  name: 'PushMessageDetail',
  computed: {
    SPECIFY_USER_LIST() {
      const { sendTarget, sendTargetData } = this.messageData;
      if (sendTarget === 'SPECIFY_TAG') {
        return sendTargetData.split(',').map((tagId) => {
          return this.userTagList.find(({ value }) => String(value) === tagId)?.label;
        })
      }
      return []
    }
  },
  data() {
    return {
      userTagList: [],
      messageData: {}
    }
  },
  methods: {
    /** 获取所有用户标签 */
    async req_getUserTagListAll() {
      const { code, result, message } = await this.$moe_api.USER_API.getUserTagListAll();
      if (code === 200) {
        this.userTagList = result.list.map(({ name, id }) => {
          return {
            label: name,
            value: id
          }
        })
      } else {
        this.$moe_msg.error(message);
      }
    },
    /** 获取短信详情 */
    req_getMessageDetai() {
      let id = this.$route.query.id;
      if (id) {
        this.$moe_api.PUSH_API.getMessageDetai({ id }).then((data) => {
          if (data.code === 200) {
            this.messageData = data.result;
            if (this.messageData.sendTarget === 'SPECIFY_USER' && this.messageData.sendTargetData) {
              this.messageData.sendTargetData = this.messageData.sendTargetData.split(',').map((id) => {
                return {
                  id
                }
              })
            }
            if (this.messageData.sendExcludeData) {
              this.messageData.sendExcludeData = this.messageData.sendExcludeData.split(',').map((id) => {
                return {
                  id
                }
              })
            }
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      }
    },
  },
  mounted() {
    this.req_getUserTagListAll();
    this.req_getMessageDetai();
  }
}
</script>